.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; 
    position: relative;
    z-index: 999;
    background: var(--white);
}

.footerContent {
    width: 1340px;
    display: flex;
    justify-content: space-between;
}

.footerleft {
    display: flex;
    align-items: center;
}

.footerSocial {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    gap: 24px;
    margin: 0 0 0 32px;
}

.email {
    display: inline-block;
    width: 24px; 
    height: 24px; 
    background-image: url('./icon/email.svg'); 
    background-size: cover; 
    margin-right: 10px; 
    vertical-align: middle;
}

.phone {
    display: inline-block;
    width: 24px; 
    height: 24px; 
    background-image: url('./icon/phone.svg'); 
    background-size: cover; 
    margin-right: 10px; 
    vertical-align: middle;
}

.clock {
    display: inline-block;
    width: 24px; 
    height: 24px; 
    background-image: url('./icon/clock.svg'); 
    background-size: cover; 
    margin-right: 10px; 
    vertical-align: middle;
}

.footerSocial div a {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    color: #292D32;
    font-size: 20px;
    font-style: normal;
}

.footerRight{
    color: #292D32;
    font-size: 20px;
    font-style: normal;
    display: flex;
    align-items: center;
}

.footerRight div{
    display: flex;
    align-items: center;
}

.footerClock p {
    font-family: var(--fonts-default);
}

.footer a {
    font-family: var(--fonts-default);
}

.footerMob{
    display: none;
}

@media (max-device-width: 575.98px){
    .footerleft,
    .footerRight{
        display: none;
    }

    .footerMob {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 16px 16px 24px 16px;
        align-items: center;
        gap: 20px;
    }

    .footerContent {
        width: 100%;
        display: flex;
    }

    .footer{
        height: 176px;
    }

    .footerMobSocial a {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .footerClock{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .footerMobSocial {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media (min-device-width:1023.98px) and (max-device-width: 1279.98px) {
    .footerContent {
        width: 1023px;
        padding: 10px 24px;
    }
}

@media (min-device-width:1279.99px) and (max-device-width: 1439.98px) {
    .footerContent {
        width: 1280px;
        padding: 10px 160px;
    }
}