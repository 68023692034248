@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  --orange: #ec6e05;
  --light-orange: #ff8e30;
  --white: #ffffff;
  --navy-blue: #292d32;
  --grey: #b5b5b5;
  --dark-grey: #696f79;
  --background: #f6f6f6;
  --overflow: #b5b5b505;
  --fonts-default: "Roboto", sans-serif;
  --black: #000000;
  --light-grey: #d9d9d9;
  --new-grey: #8692a6;
  --red: #dd4130;
  --green: #08ad36;
  --yellow: #ffd500;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

g.highcharts-axis.highcharts-yaxis {
  display: none;
}

.chartBox {
  display: none;
}

.loading {
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/3794f505ceb4aef5-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/320d9f5d177d6ec2-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/ca9e8d8193aed290-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/80512c49369d7ad3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* math */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/0d580af215996300-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0310, U+0312, U+0315, U+031A, U+0326-0327, U+032C, U+032F-0330, U+0332-0333, U+0338, U+033A, U+0346, U+034D, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2016-2017, U+2034-2038, U+203C, U+2040, U+2043, U+2047, U+2050, U+2057, U+205F, U+2070-2071, U+2074-208E, U+2090-209C, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2100-2112, U+2114-2115, U+2117-2121, U+2123-214F, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B7, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+3030, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* symbols */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/14254a1c498c2b09-s.woff2) format('woff2');
  unicode-range: U+0001-000C, U+000E-001F, U+007F-009F, U+20DD-20E0, U+20E2-20E4, U+2150-218F, U+2190, U+2192, U+2194-2199, U+21AF, U+21E6-21F0, U+21F3, U+2218-2219, U+2299, U+22C4-22C6, U+2300-243F, U+2440-244A, U+2460-24FF, U+25A0-27BF, U+2800-28FF, U+2921-2922, U+2981, U+29BF, U+29EB, U+2B00-2BFF, U+4DC0-4DFF, U+FFF9-FFFB, U+10140-1018E, U+10190-1019C, U+101A0, U+101D0-101FD, U+102E0-102FB, U+10E60-10E7E, U+1D2C0-1D2D3, U+1D2E0-1D37F, U+1F000-1F0FF, U+1F100-1F1AD, U+1F1E6-1F1FF, U+1F30D-1F30F, U+1F315, U+1F31C, U+1F31E, U+1F320-1F32C, U+1F336, U+1F378, U+1F37D, U+1F382, U+1F393-1F39F, U+1F3A7-1F3A8, U+1F3AC-1F3AF, U+1F3C2, U+1F3C4-1F3C6, U+1F3CA-1F3CE, U+1F3D4-1F3E0, U+1F3ED, U+1F3F1-1F3F3, U+1F3F5-1F3F7, U+1F408, U+1F415, U+1F41F, U+1F426, U+1F43F, U+1F441-1F442, U+1F444, U+1F446-1F449, U+1F44C-1F44E, U+1F453, U+1F46A, U+1F47D, U+1F4A3, U+1F4B0, U+1F4B3, U+1F4B9, U+1F4BB, U+1F4BF, U+1F4C8-1F4CB, U+1F4D6, U+1F4DA, U+1F4DF, U+1F4E3-1F4E6, U+1F4EA-1F4ED, U+1F4F7, U+1F4F9-1F4FB, U+1F4FD-1F4FE, U+1F503, U+1F507-1F50B, U+1F50D, U+1F512-1F513, U+1F53E-1F54A, U+1F54F-1F5FA, U+1F610, U+1F650-1F67F, U+1F687, U+1F68D, U+1F691, U+1F694, U+1F698, U+1F6AD, U+1F6B2, U+1F6B9-1F6BA, U+1F6BC, U+1F6C6-1F6CF, U+1F6D3-1F6D7, U+1F6E0-1F6EA, U+1F6F0-1F6F3, U+1F6F7-1F6FC, U+1F700-1F7FF, U+1F800-1F80B, U+1F810-1F847, U+1F850-1F859, U+1F860-1F887, U+1F890-1F8AD, U+1F8B0-1F8BB, U+1F8C0-1F8C1, U+1F900-1F90B, U+1F93B, U+1F946, U+1F984, U+1F996, U+1F9E9, U+1FA00-1FA6F, U+1FA70-1FA7C, U+1FA80-1FA89, U+1FA8F-1FAC6, U+1FACE-1FADC, U+1FADF-1FAE9, U+1FAF0-1FAF8, U+1FB00-1FBFF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/4036a8cc6ad3520f-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c7128a8004343716-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_10f679';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/c4a2ca76cbcd952a-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_10f679';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_10f679 {font-family: '__Roboto_10f679', '__Roboto_Fallback_10f679';font-weight: 400;font-style: normal
}

.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; 
    position: relative;
    z-index: 999;
    background: var(--white);
}

.footerContent {
    width: 1340px;
    display: flex;
    justify-content: space-between;
}

.footerleft {
    display: flex;
    align-items: center;
}

.footerSocial {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    position: relative;
    gap: 24px;
    margin: 0 0 0 32px;
}

.email {
    display: inline-block;
    width: 24px; 
    height: 24px; 
    background-image: url(/_next/static/media/email.d706d65d.svg); 
    background-size: cover; 
    margin-right: 10px; 
    vertical-align: middle;
}

.phone {
    display: inline-block;
    width: 24px; 
    height: 24px; 
    background-image: url(/_next/static/media/phone.949e3b6d.svg); 
    background-size: cover; 
    margin-right: 10px; 
    vertical-align: middle;
}

.clock {
    display: inline-block;
    width: 24px; 
    height: 24px; 
    background-image: url(/_next/static/media/clock.f45c3a4b.svg); 
    background-size: cover; 
    margin-right: 10px; 
    vertical-align: middle;
}

.footerSocial div a {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    color: #292D32;
    font-size: 20px;
    font-style: normal;
}

.footerRight{
    color: #292D32;
    font-size: 20px;
    font-style: normal;
    display: flex;
    align-items: center;
}

.footerRight div{
    display: flex;
    align-items: center;
}

.footerClock p {
    font-family: var(--fonts-default);
}

.footer a {
    font-family: var(--fonts-default);
}

.footerMob{
    display: none;
}

@media (max-device-width: 575.98px){
    .footerleft,
    .footerRight{
        display: none;
    }

    .footerMob {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 16px 16px 24px 16px;
        align-items: center;
        gap: 20px;
    }

    .footerContent {
        width: 100%;
        display: flex;
    }

    .footer{
        height: 176px;
    }

    .footerMobSocial a {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .footerClock{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .footerMobSocial {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

@media (min-device-width:1023.98px) and (max-device-width: 1279.98px) {
    .footerContent {
        width: 1023px;
        padding: 10px 24px;
    }
}

@media (min-device-width:1279.99px) and (max-device-width: 1439.98px) {
    .footerContent {
        width: 1280px;
        padding: 10px 160px;
    }
}
.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.navigation {
    width: 1340px;
    display: flex;
    justify-content: space-between;
}

.navigationLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 32px;
}

.navigationLink {
    display: flex;
    gap: 32px;
}

.linkPage {
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    color:var(--navy-blue);
    font-size: 20px;
}

.navigationRight {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 24px;
}

.btnSignUp {
    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--orange);
    background: var(--white);
    color: var(--orange);
    transition: all 0.5s ease;
    cursor: pointer;
}

.btnSignIn{
    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--orange);
    color: var(--white);
    transition: all 0.5s ease;
    cursor: pointer;
}

.btnSignUp a,
.btnSignIn a{
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-family: var(--fonts-default);
    font-weight: 400;
}

.activeLink{
    color: var(--orange);
    font-weight: 500;
}

span.linkPage a span {
    position: relative;
    display: inline-block;
    font-family: var(--fonts-default);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

span.linkPage a span:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--navy-blue);
    transition: width 0.5s ease;
}

span.linkPage a span:hover:after {
    width: 100%;
}


span.linkPage a.activeLink span:after{
    width: 100%;
    background-color: var(--orange);
}

.btnSignIn:hover{
    background-color: var(--light-orange)
}

.btnSignUp:hover{
    background-color: var(--light-orange);
    color: var(--white);
    border-color: var(--light-orange);
}

.btnSignIn:active{
    background-color: var(--orange)
}

.btnSignUp:active{
    background-color: var(--orange);
    color: var(--white);
    border-color: var(--orange);
}


.authInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    color: var(--navy-blue);
    font-family: var(--fonts-default);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.authInfo img{
    width: 48px;
    height: 48px;
    border-radius: 40px;
}

.navMobile{
    display: none;
}

@media (max-device-width: 575.98px) {
    .navigationLink,
    .navigationRight{
        display: none;
    }

    .navbar{
        padding: 16px 10px;
    }

    .navMobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navMobile svg{
        cursor: pointer;
    }

    .openNav{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var( --background);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 999999;
        padding: 26px 16px 16px 16px;
    }

    .modal {
        width: 100%;
        height: 100%;
    }

    .closeBtn {
        display: flex;
        justify-content: flex-end;
    }

    .mobLinkNav {
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: var(--white);
        height: 44px;
        padding: 0 15px;
        justify-content: space-between;
        cursor: pointer;
    }

    .mobileLink {
        margin: 34px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    }

    .mobLinkNav a {
        color: var(--navy-blue);
        font-family: var(--fonts-default);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobBtn {
        position: absolute;
        bottom: 0;
        margin: 0 16px 16px 0px;
        width: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
    }

    .mobBtnSignUp {
        border-radius: 4px;
        border: 1px solid var(--orange);
        background: var(--white);
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .mobBtnSignUp a {
        color: var(--orange);
        font-family: var(--fonts-default);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobBtnSignIn {
        border-radius: 4px;
        background: var(--orange);
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    
    .mobBtnSignIn a {
        color: var(--white);
        font-family: var(--fonts-default);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .mobNavLogo {
        position: absolute;
        top: 20px;
    }

    .mobLinkNav p {
        color: var(--navy-blue);
        font-family: var(--fonts-default);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media (min-device-width:1023.98px) and (max-device-width: 1279.98px) {
    .navigation {
        width: 1023px;
        padding: 10px 24px;
    }
}

@media (min-device-width:1279.99px) and (max-device-width: 1439.98px) {
    .navigation {
        width: 1280px;
        padding: 10px 160px;
    }
}
