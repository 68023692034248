.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.navigation {
    width: 1340px;
    display: flex;
    justify-content: space-between;
}

.navigationLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 32px;
}

.navigationLink {
    display: flex;
    gap: 32px;
}

.linkPage {
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    color:var(--navy-blue);
    font-size: 20px;
}

.navigationRight {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 24px;
}

.btnSignUp {
    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--orange);
    background: var(--white);
    color: var(--orange);
    transition: all 0.5s ease;
    cursor: pointer;
}

.btnSignIn{
    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--orange);
    color: var(--white);
    transition: all 0.5s ease;
    cursor: pointer;
}

.btnSignUp a,
.btnSignIn a{
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    font-family: var(--fonts-default);
    font-weight: 400;
}

.activeLink{
    color: var(--orange);
    font-weight: 500;
}

span.linkPage a span {
    position: relative;
    display: inline-block;
    font-family: var(--fonts-default);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
}

span.linkPage a span:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--navy-blue);
    transition: width 0.5s ease;
}

span.linkPage a span:hover:after {
    width: 100%;
}


span.linkPage a.activeLink span:after{
    width: 100%;
    background-color: var(--orange);
}

.btnSignIn:hover{
    background-color: var(--light-orange)
}

.btnSignUp:hover{
    background-color: var(--light-orange);
    color: var(--white);
    border-color: var(--light-orange);
}

.btnSignIn:active{
    background-color: var(--orange)
}

.btnSignUp:active{
    background-color: var(--orange);
    color: var(--white);
    border-color: var(--orange);
}


.authInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    color: var(--navy-blue);
    font-family: var(--fonts-default);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.authInfo img{
    width: 48px;
    height: 48px;
    border-radius: 40px;
}

.navMobile{
    display: none;
}

@media (max-device-width: 575.98px) {
    .navigationLink,
    .navigationRight{
        display: none;
    }

    .navbar{
        padding: 16px 10px;
    }

    .navMobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navMobile svg{
        cursor: pointer;
    }

    .openNav{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var( --background);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 999999;
        padding: 26px 16px 16px 16px;
    }

    .modal {
        width: 100%;
        height: 100%;
    }

    .closeBtn {
        display: flex;
        justify-content: flex-end;
    }

    .mobLinkNav {
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: var(--white);
        height: 44px;
        padding: 0 15px;
        justify-content: space-between;
        cursor: pointer;
    }

    .mobileLink {
        margin: 34px 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
    }

    .mobLinkNav a {
        color: var(--navy-blue);
        font-family: var(--fonts-default);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobBtn {
        position: absolute;
        bottom: 0;
        margin: 0 16px 16px 0px;
        width: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
    }

    .mobBtnSignUp {
        border-radius: 4px;
        border: 1px solid var(--orange);
        background: var(--white);
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .mobBtnSignUp a {
        color: var(--orange);
        font-family: var(--fonts-default);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .mobBtnSignIn {
        border-radius: 4px;
        background: var(--orange);
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    
    .mobBtnSignIn a {
        color: var(--white);
        font-family: var(--fonts-default);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    
    .mobNavLogo {
        position: absolute;
        top: 20px;
    }

    .mobLinkNav p {
        color: var(--navy-blue);
        font-family: var(--fonts-default);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media (min-device-width:1023.98px) and (max-device-width: 1279.98px) {
    .navigation {
        width: 1023px;
        padding: 10px 24px;
    }
}

@media (min-device-width:1279.99px) and (max-device-width: 1439.98px) {
    .navigation {
        width: 1280px;
        padding: 10px 160px;
    }
}